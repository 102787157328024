



















import { Component, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import TheVerticalCenter from "@/components/TheVerticalCenter.vue";

@Component({ components: { AppButton, TheVerticalCenter } })
export default class Error extends Vue {
  goHome(): void {
    this.$router.push({ name: "HOME" });
  }
}
